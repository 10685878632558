export const CheckIcon = ({
  className,
  style,
}: {
  className?: string
  style?: any
}) => (
  <svg
    width='18'
    height='14'
    viewBox='0 0 18 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    style={style}
  >
    <path
      d='M7.00962 13.7303C6.84734 13.9034 6.62594 14 6.39596 14C6.16598 14 5.94458 13.9034 5.7823 13.7303L0.881451 8.53168C0.37285 7.99227 0.37285 7.11758 0.881451 6.57917L1.49511 5.92817C2.00387 5.38875 2.82765 5.38875 3.33625 5.92817L6.39596 9.17342L14.6638 0.404561C15.1725 -0.134854 15.9971 -0.134854 16.5049 0.404561L17.1185 1.05557C17.6272 1.59498 17.6272 2.46951 17.1185 3.00808L7.00962 13.7303Z'
      fill='currentColor'
    />
  </svg>
)
