import { HomeUI } from "./style";

export const Subscription = () => {
  return (
    <HomeUI>
      <h1>Subscription</h1>
    </HomeUI>
  );
};

export default Subscription;
