export const PlusIcon = ({ className }: { className?: string }) => (
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M57.4998 27H33.5002V2.99982C33.5002 1.34418 32.156 0 30.4998 0C28.8442 0 27.5 1.34418 27.5 2.99982V27H3.49982C1.84418 27 0.5 28.3442 0.5 29.9998C0.5 31.656 1.84418 33.0002 3.49982 33.0002H27.5V56.9998C27.5 58.656 28.8442 60.0002 30.4998 60.0002C32.156 60.0002 33.5002 58.656 33.5002 56.9998V33.0002H57.4998C59.156 33.0002 60.5002 31.656 60.5002 29.9998C60.5002 28.3442 59.156 27 57.4998 27Z"
      fill="currentColor"
    />
  </svg>
);
