export const HEADER_LINKS = [
  {
    path: '/',
    label: 'Home',
  },
  // {
  //   path: '/subcription',
  //   label: 'Buy zkHive Subcription',
  // },
  {
    path: '/buy-node',
    label: 'Buy Nodes',
  },
  {
    path: '/my-node',
    label: 'My Nodes',
  },
]
