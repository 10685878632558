import { HomeUI } from "./style";

export const HomePage = () => {
  return (
    <HomeUI>
      <h1>Welcome to zkNodes, Powered by zkHive</h1>
    </HomeUI>
  );
};

export default HomePage;
